import React, { useContext, useEffect, useState } from 'react'
import { BoxCard, TableBox } from './component/Charts'
import { MdOutlineSsidChart, MdPeopleOutline } from 'react-icons/md'
import { TbSum } from 'react-icons/tb'
import { GrCycle } from 'react-icons/gr'
import { useLocation, useNavigate } from 'react-router-dom'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import axios from 'axios'

import ApexCharts from 'apexcharts'
import { useTheme } from '../../contexts/ThemeContext'

const Statistics = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const axiosPrivate = useAxiosPrivate()

  const [data, setData] = useState([])
  const [metrics, setMetrics] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { theme } = useTheme()

  useEffect(() => {
    if (data) {
      const { scope1, scope2, scope3 } = data // Extract only the desired keys
      const newData = [
        parseFloat(scope1),
        parseFloat(scope2),
        parseFloat(scope3),
      ]
      if (theme == 'dark') {
        pieChartRender('#d1d5db', newData)
        columnChartRender('#d1d5db')
        lineChartRender('#d1d5db')
      } else if (theme == 'light') {
        pieChartRender('#6b7280', newData)
        columnChartRender('#6b7280')
        lineChartRender('#6b7280')
      }
    }
  }, [theme, data])

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await axiosPrivate.get(
          '/record/total-emission-amount',
          {
            signal: controller.signal,
          },
        )

        if (isMounted) {
          setData(response.data)
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          // Log for debugging purposes
          // console.log('Request cancelled:', err.message);
        } else {
          console.error('Request failed:', err)
          // Only navigate if the error was not a cancellation
          // Check for a 403 status code specifically
          if (err.response && err.response.status === 403) {
            navigate('/sign-in', { state: { from: location }, replace: true })
          }
        }
      }
      setIsLoading(false)
    }

    fetchData()

    return () => {
      isMounted = false
      controller.abort()
      // console.log('Cleanup: Cancelled any ongoing requests.');
    }
  }, [navigate, location, axiosPrivate])

  // useEffect(() => {
  //   let isMounted = true
  //   const controller = new AbortController()

  //   const fetchData = async () => {
  //     try {
  //       const response = await axiosPrivate.get('/record/home-data', {
  //         signal: controller.signal,
  //       })

  //       if (isMounted) {
  //         // console.log(response.data);
  //         setMetrics(response.data)
  //       }
  //     } catch (err) {
  //       if (axios.isCancel(err)) {
  //         // Log for debugging purposes
  //         // console.log('Request cancelled:', err.message);
  //       } else {
  //         console.error('Request failed:', err)
  //         // Only navigate if the error was not a cancellation
  //         // Check for a 403 status code specifically
  //         if (err.response && err.response.status === 403) {
  //           navigate('/sign-in', { state: { from: location }, replace: true })
  //         }
  //       }
  //     }
  //   }

  //   fetchData()

  //   return () => {
  //     isMounted = false
  //     controller.abort()
  //     // console.log('Cleanup: Cancelled any ongoing requests.');
  //   }
  // }, [navigate, location, axiosPrivate])

  function pieChartRender(labelColor, value) {
    var chart = document.querySelector('#chartpie')
    chart.innerHTML = ''
    var options = {
      series: value ?? [0],
      chart: {
        height: 300,
        type: 'pie',
      },
      labels: [`Scope 1 (tonnes CO2e): ${value[0] ?? 0}`, `Scope 2 (tonnes CO2e): ${value[1] ?? 0}`, `Scope 3 (tonnes CO2e): ${value[2] ?? 0}`],
      legend: {
        show: true,
        position: 'right',
        horizontalAlign: 'left',
        floating: false,
        fontSize: '14px',
        fontFamily: 'Inter',
        fontWeight: 400,
        labels: {
          colors: labelColor,
          useSeriesColors: false,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    }

    var chart = new ApexCharts(chart, options)
    chart.render()
  }

  function columnChartRender(labelColor) {
    var chart = document.querySelector('#chartcolumn')
    chart.innerHTML = ''
    var options = {
      series: [
        {
          name: 'Scope 1',
          data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
        },
        {
          name: 'Scope 2',
          data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
        },
        {
          name: 'Scope 3',
          data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
        },
      ],
      chart: {
        type: 'bar',
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'center',
        floating: false,
        fontSize: '14px',
        fontFamily: 'Inter',
        fontWeight: 400,
        labels: {
          // colors: '#9ca3af',
          colors: labelColor,
          useSeriesColors: false,
        },
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        title: {
          text: 'Months',
          style: {
            color: labelColor,
            fontWeight: '500',
            fontSize: '12px',
          },
        },
        categories: [
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
        ],
        labels: {
          style: {
            colors: labelColor,
          },
        },
      },
      yaxis: {
        title: {
          text: 'kg CO2',
          style: {
            color: labelColor,
            fontWeight: '500',
            fontSize: '14px',
          },
        },
        labels: {
          style: {
            colors: labelColor,
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return +val + ' kg CO2'
          },
        },
      },
    }

    var chart = new ApexCharts(chart, options)
    chart.render()
  }

  function lineChartRender(labelColor) {
    var chart = document.querySelector('#chartline')
    chart.innerHTML = ''
    var options = {
      series: [
        {
          name: 'kg CO2',
          data: [28, 96, 33, 51, 76, 32, 100],
        },
      ],
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true,
        },
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          autoSelected: 'zoom',
          style: {
            color: 'white',
          },
        },
      },
      colors: ['#77B6EA'],
      dataLabels: {
        enabled: true,
      },
      title: {
        text: 'Moving Average Graph',
        align: 'left',
        style: {
          fontWeight: 'bold',
          color: labelColor,
        },
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      markers: {
        size: 1,
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
        title: {
          text: 'Months',
          style: {
            color: labelColor,
            fontWeight: '500',
            fontSize: '12px',
          },
        },
        labels: {
          style: {
            colors: labelColor,
          },
        },
      },
      yaxis: {
        title: {
          text: 'Temperature',
          style: {
            color: labelColor,
            fontWeight: '500',
            fontSize: '14px',
          },
        },
        labels: {
          style: {
            colors: labelColor,
          },
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5,
      },
    }

    var chart = new ApexCharts(chart, options)
    chart.render()
  }

  return (
    <div className="flex flex-col bg-slate-400 dark:bg-stone-800 w-full h-full overflow-y-auto max-h-full max-w-full">
      <div className="grid pl-4 pr-4 font-['Inter'] rounded-3xl overflow-y-auto pt-8">
        {/* mb-4 pb-10 px-8 mx-4 */}
        <div className="grid grid-cols-12 gap-6">
          <div className="grid grid-cols-12 col-span-12 gap-6 xxl:col-span-9">
            <div className="col-span-12 md:col-span-6">
              <div className="grid grid-cols-6 grid-rows-2 gap-6">
                <BoxCard
                  title={'Total Carbon Emission'}
                  value={data?.total || 0}
                  unit={'tonnes CO2e'}
                  Icon={TbSum}
                  link="#"
                  extraColor={'green-500'}
                />
                <BoxCard
                  title={'Yearly Change in Carbon Percentage'}
                  value={metrics?.totalEmission || 0}
                  unit={'tonnes CO2e'}
                  Icon={MdOutlineSsidChart}
                  link="#"
                  extraColor={'red-500'}
                />
                <BoxCard
                  title={'Carbon Offset'}
                  value={metrics?.totalEmission || 0}
                  unit={'tonnes CO2e'}
                  Icon={GrCycle}
                  link="#"
                  extraColor={'yellow-500'}
                />
                <BoxCard
                  title={'Carbon Footprint per Employee'}
                  value={metrics?.totalEmission || 0}
                  unit={'tonnes CO2e'}
                  Icon={MdPeopleOutline}
                  link="#"
                  extraColor={'blue-500'}
                />
              </div>
            </div>
            <div className="col-span-12 md:col-span-6">
              <div
                className="bg-white shadow-lg items-center justify-center flex border dark:bg-darkbg4 border-gray-300 rounded-md h-full"
                id="chartpie"
              ></div>
            </div>
            <div className="col-span-12 mt-5 ">
                <TableBox data={data} />
            </div>
            <div className="col-span-12 mt-5 border border-gray-300 rounded-md shadow-lg dark:bg-darkbg4">
              <div
                className="overflow-hidden sm:rounded-lg bg-white dark:bg-darkbg4 p-4"
                id="chartcolumn"
              ></div>
            </div>

            <div className="col-span-12 mt-5 border border-gray-300 rounded-md shadow-lg dark:bg-darkbg4 mb-10">
              <div
                className="overflow-hidden sm:rounded-lg bg-white dark:bg-darkbg4 p-4"
                id="chartline"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Statistics
