import React, { useEffect, useState } from 'react'
import { TableS3D } from './component/Table'
import { columnDefinitionTransportationDistribution } from './component/Table/column'
import { FiPlus, FiTrash } from 'react-icons/fi'
import { useLocation, useNavigate } from 'react-router-dom'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import axios from 'axios'
import * as types from './reportData.json'

const ReportTransportationDistribution = () => {
  const column = columnDefinitionTransportationDistribution
  const navigate = useNavigate()
  const location = useLocation()
  const axiosPrivate = useAxiosPrivate()
  const { bypassModal } = location.state === null ? false : location.state
  const {
    fuel,
    fuelEF,
    vehicleEF,
    vehicleMode,
    transportMode,
    refrigerant,
    malaysiaRegion
  } = types

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showContentModal, setShowContentModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [modalMode, setModalMode] = useState(null) // 'add' or 'edit'
  const [editableData, setEditableData] = useState({
    category: '',
    emissionType: '',
    reportBy: '',
    calculateBy: '',
    fuel: '',
    vehicleMode: '',
    vehicleType: '',
    fuelUnit: '',
    quantity: 0,
    mass: 0,
    laden: '',
    transportMode: '',
    margin: '',
    region: '',
    allocation: 0,
    totalAllocation: 0,
    occupancyRate: 0,
    electricity: 0,
    refrigerant: '',
    leakage: 0,
    averageDays: 0,
    storageEF: 0,
    description: '',
  })
  const [selectedRows, setSelectedRows] = useState(new Set())
  const [rowToDelete, setRowToDelete] = useState(null)
  const [categoryToDelete, setCategoryToDelete] = useState(null)

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const fetchData = async () => {
      setIsLoading(true)
      try {
        const upstreamResponse = await axiosPrivate.get(
          'upstreamTD/read-S3D-records',
          {
            signal: controller.signal,
          },
        )

        const downstreamResponse = await axiosPrivate.get(
          'downstreamTD/read-S3E-records',
          {
            signal: controller.signal,
          },
        )

        if (isMounted) {
          // console.log(response.data);
          setData([...upstreamResponse.data, ...downstreamResponse.data])
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          // Log for debugging purposes
          // console.log('Request cancelled:', err.message);
        } else {
          console.error('Request failed:', err)
          // Only navigate if the error was not a cancellation
          // Check for a 403 status code specifically
          if (err.response && err.response.status === 403) {
            navigate('/sign-in', { state: { from: location }, replace: true })
          }
        }
      }

      setIsLoading(false)
    }

    fetchData()

    if (bypassModal === true) {
      handleAddNewClick()
    }

    return () => {
      isMounted = false
      controller.abort()
      // console.log('Cleanup: Cancelled any ongoing requests.');
    }
  }, [navigate, location, axiosPrivate])

  const handleAddNewClick = () => {
    setEditableData({
      category: '',
      emissionType: '',
      reportBy: '',
      calculateBy: '',
      fuel: '',
      vehicleMode: '',
      vehicleType: '',
      fuelUnit: '',
      quantity: 0,
      mass: 0,
      laden: '',
      transportMode: '',
      margin: '',
      region: '',
      allocation: 0,
      totalAllocation: 0,
      occupancyRate: 0,
      electricity: 0,
      refrigerant: '',
      leakage: 0,
      averageDays: 0,
      storageEF: 0,
      description: '',
    })
    setModalMode('add')
    setShowContentModal(true)
  }

  const handleDeleteClick = () => {
    if (selectedRows.size > 0) {
      setShowDeleteModal(true)
    } else {
      alert('No rows selected') // Or show a more user-friendly message
    }
  }

  const requestRowDeletion = (rowCategory, rowId) => {
    setCategoryToDelete(rowCategory)
    setRowToDelete(rowId)
    setShowDeleteModal(true)
  }

  const confirmDelete = async () => {
    if (rowToDelete !== null) {
      let isMounted = true
      const controller = new AbortController()

      try {
        const stream = categoryToDelete === 'Upstream' ? 'D' : 'E'
        await axiosPrivate.delete(
          `/${categoryToDelete.toLowerCase()}TD/delete-S3${stream}-record/${rowToDelete}`,
          {
            headers: { 'Content-Type': 'application/json' },
            signal: controller.signal,
          },
        )

        if (isMounted) {
          // Update the state to reflect the deletion
          setData((prevData) =>
            prevData.filter((row) => row.id !== rowToDelete),
          )
          setRowToDelete(null)
        }
      } catch (err) {
        if (!axios.isCancel(err)) {
          console.error('Deletion failed:', err)
          if (err.response) {
            // Redirect on 403 Forbidden or show an error message
            if (err.response.status === 403) {
              navigate('/sign-in', {
                state: { from: location },
                replace: true,
              })
            } else {
              alert(
                `Error: ${err.response.data.message || 'An error occurred.'}`,
              )
            }
          }
        }
      } finally {
        if (isMounted) {
          setShowDeleteModal(false) // Close the modal upon completion or error
        }
      }

      // Cleanup function to prevent state updates if the component unmounts
      return () => {
        isMounted = false
        controller.abort()
      }
    } else {
      // If rowToDelete is null, just close the modal
      setShowDeleteModal(false)
    }
  }

  const deleteSelectedRows = async () => {
    // Check if any rows are selected for deletion
    if (selectedRows.size > 0) {
      let isMounted = true
      const controller = new AbortController()

      try {
        const recordIdsToDelete = Array.from(selectedRows)

        await axiosPrivate.delete('/businessTravel/delete-S3G-records', {
          data: { ids: recordIdsToDelete }, // Sending user IDs in the request body
          headers: { 'Content-Type': 'application/json' },
          signal: controller.signal,
        })

        if (isMounted) {
          // Filter out the deleted users from the current data
          setData((prevData) =>
            prevData.filter((row) => !selectedRows.has(row.id)),
          )
          setSelectedRows(new Set()) // Clear selected rows after successful deletion
        }
      } catch (err) {
        if (!axios.isCancel(err)) {
          console.error('Deletion failed:', err)
          if (err.response) {
            // Handle specific HTTP status codes here
            if (err.response.status === 403) {
              navigate('/sign-in', {
                state: { from: location },
                replace: true,
              })
            } else {
              alert(
                `Error: ${err.response.data.message || 'An error occurred.'}`,
              )
            }
          }
        }
      } finally {
        if (isMounted) {
          setShowDeleteModal(false) // Close the modal upon completion or error
        }
      }

      // Cleanup function to prevent state updates if the component unmounts
      return () => {
        isMounted = false
        controller.abort()
      }
    } else {
      // If no rows were selected, just close the modal
      setShowDeleteModal(false)
    }
  }

  // Function to handle edit requests
  const handleEditClick = (rowData) => {
    setEditableData(rowData)
    setModalMode('edit')
    setShowContentModal(true)
  }

  const handleAddNewSubmit = async (event) => {
    event.preventDefault()
    const stream = editableData.category === 'Upstream' ? 'D' : 'E'

    const endpoint =
      modalMode === 'edit'
        ? `/${editableData.category.toLowerCase()}TD/edit-S3${stream}-record/${
            editableData.id
          }`
        : `/${editableData.category.toLowerCase()}TD/create-S3${stream}-record`
    const method = modalMode === 'edit' ? 'patch' : 'post'

    // Prepare the data payload
    const payload = {
      scope: 3,
      category: `${editableData.category}`,
      emissionType: editableData.emissionType,
      reportBy: editableData.reportBy,
      calculateBy: editableData.calculateBy,
      fuel: editableData.fuel,
      vehicleMode: editableData.vehicleMode,
      vehicleType: editableData.vehicleType,
      fuelUnit: editableData.fuelUnit,
      quantity: editableData.quantity,
      mass: editableData.mass,
      laden: editableData.laden,
      transportMode: editableData.transportMode,
      margin: editableData.margin,
      region: editableData.region,
      allocation: editableData.allocation,
      totalAllocation: editableData.totalAllocation,
      occupancyRate: editableData.occupancyRate,
      electricity: editableData.electricity,
      refrigerant: editableData.refrigerant,
      leakage: editableData.leakage,
      averageDays: editableData.averageDays,
      storageEF: editableData.storageEF,
      description: editableData.description,
    }

    try {
      const response = await axiosPrivate[method](endpoint, payload, {
        headers: { 'Content-Type': 'application/json' },
      })

      // Update the state based on the modal mode
      if (modalMode === 'edit') {
        setData((currentData) =>
          currentData.map((item) =>
            item.id === editableData.id ? { ...item, ...response.data } : item,
          ),
        )
      } else {
        setData((currentData) => [...currentData, response.data])
      }

      // Reset form and close modal
      setEditableData({
        category: '',
        emissionType: '',
        reportBy: '',
        calculateBy: '',
        fuel: '',
        vehicleMode: '',
        vehicleType: '',
        fuelUnit: '',
        quantity: 0,
        mass: 0,
        laden: '',
        transportMode: '',
        margin: '',
        region: '',
        allocation: 0,
        totalAllocation: 0,
        occupancyRate: 0,
        electricity: 0,
        refrigerant: '',
        leakage: 0,
        averageDays: 0,
        storageEF: 0,
        description: '',
      })
      setShowContentModal(false)
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.error('Submission failed:', err)
        const errorMsg = err.response?.data.message || 'An error occurred.'
        if (err.response?.status === 403) {
          navigate('/sign-in', { state: { from: location }, replace: true })
        } else {
          alert(`Error: ${errorMsg}`)
        }
      }
    }
  }

  function typeOption() {
    const transportationReport = [
      'Fuel Consumption',
      'Distance Travelled',
      'Amount Spent',
    ]
    const distributionReport = ['Site-Specific Method', 'Average-Data Method']

    return editableData.emissionType === 'Transportation'
      ? transportationReport
      : distributionReport
  }

  function quantityLabel() {
    const labels = {
      'Fuel Consumption': 'Fuel Consumed',
      'Distance Travelled': 'Distance Transported (km)',
      'Amount Spent': 'Amount Spent (RM)',
      'Site-Specific Method': 'Fuel Consumed',
    }

    if (editableData.reportBy !== 'Average-Data Method') {
      return labels[editableData.reportBy]
    } else {
      return (
        <p>
          Total Volume of Stored Good (m<sup>3</sup>)
        </p>
      )
    }
  }

  if (isLoading) {
    return <div>Loading...</div> // Or any loading spinner
  }

  return (
    <div className="flex flex-col bg-slate-400 dark:bg-stone-800 text-black dark:text-white p-4 md:p-10 w-full overflow-y-auto h-full">
      <div className="flex flex-col md:flex-row justify-between items-center mb-4">
        <h2 className="text-xl md:text-2xl font-bold mb-4 md:mb-0 dark:text-white">
          Transportation and Distribution Reporting
        </h2>
        <div className="flex space-x-2">
          <button
            onClick={handleAddNewClick}
            className="flex items-center justify-center bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 dark:bg-blue-600 dark:hover:bg-blue-800 transition duration-300 w-40"
            aria-label="Add new item"
          >
            <FiPlus className="mr-2" />
            <span className="flex-1 text-center">Add</span>
          </button>
          <button
            onClick={handleDeleteClick}
            className="flex items-center justify-center bg-red-500 text-white py-2 px-4 rounded hover:bg-red-700 dark:bg-red-600 dark:hover:bg-red-800 transition duration-300 w-40"
            aria-label="Delete selected items"
          >
            <FiTrash className="mr-2" />
            <span className="flex-1 text-center">Delete</span>
          </button>
        </div>
      </div>
      {/* {showContentModal && <MyModal onClose={() => setShowContentModal(false)} />} */}
      <TableS3D
        data={data}
        columns={column}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        onRequestDelete={requestRowDeletion}
        onEdit={handleEditClick}
      />

      {showDeleteModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md flex justify-center items-center p-4">
          <div className="bg-white dark:bg-gray-800 w-full max-w-md mx-auto rounded shadow-lg overflow-hidden p-4">
            <p className="text-gray-800 dark:text-gray-200 text-center text-lg md:text-base">
              Are you sure you want to delete the selected items?
            </p>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="bg-gray-300 dark:bg-gray-600 dark:text-gray-200 px-4 py-2 rounded mr-2 text-sm md:text-base hover:bg-gray-400 dark:hover:bg-gray-700 transition duration-300"
              >
                Cancel
              </button>
              <button
                onClick={() =>
                  rowToDelete !== null ? confirmDelete() : deleteSelectedRows()
                }
                className="bg-red-500 text-white px-4 py-2 rounded text-sm md:text-base hover:bg-red-600 transition duration-300"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      {showContentModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md flex justify-center items-center p-4">
          <div className="bg-white dark:bg-gray-800 w-full max-w-lg mx-auto rounded shadow-lg overflow-hidden p-6 max-h-[90vh] overflow-y-auto">
            <h2 className="text-lg font-semibold mb-4">
              {modalMode === 'edit' ? 'Edit Data' : 'Add New Data'}
            </h2>
            <form onSubmit={handleAddNewSubmit}>
              {/* Category Dropdown */}
              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Category
                </label>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={editableData.category}
                  onChange={(e) =>
                    setEditableData({
                      ...editableData,
                      category: e.target.value,
                      emissionType: '',
                      reportBy: '',
                      calculateBy: '',
                      fuel: '',
                      vehicleMode: '',
                      vehicleType: '',
                      fuelUnit: '',
                      quantity: 0,
                      mass: 0,
                      laden: '',
                      transportMode: '',
                      margin: '',
                      region: '',
                      allocation: 0,
                      totalAllocation: 0,
                      occupancyRate: 0,
                      electricity: 0,
                      refrigerant: '',
                      leakage: 0,
                      averageDays: 0,
                      storageEF: 0,
                      description: '',
                    })
                  }
                  required
                >
                  <option value="">Select Type</option>
                  <option value="Upstream">Upstream</option>
                  <option value="Downstream">Downstream</option>
                </select>
              </div>

              {/* Emission Type Dropdown */}
              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Emission Type
                </label>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={editableData.emissionType}
                  onChange={(e) =>
                    setEditableData({
                      ...editableData,
                      emissionType: e.target.value,
                      reportBy: '',
                      calculateBy: '',
                      fuel: '',
                      vehicleMode: '',
                      vehicleType: '',
                      fuelUnit: '',
                      quantity: 0,
                      mass: 0,
                      laden: '',
                      transportMode: '',
                      margin: '',
                      region: '',
                      allocation: 0,
                      totalAllocation: 0,
                      occupancyRate: 0,
                      electricity: 0,
                      refrigerant: '',
                      leakage: 0,
                      averageDays: 0,
                      storageEF: 0,
                      description: '',
                    })
                  }
                  required
                >
                  <option value="">Select Type</option>
                  <option value="Transportation">Transportation</option>
                  <option value="Distribution">Distribution</option>
                </select>
              </div>

              {/* ReportBy Dropdown */}
              {editableData.emissionType !== '' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Report By
                  </label>
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.reportBy}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        reportBy: e.target.value,
                        calculateBy: '',
                        fuel: '',
                        vehicleMode: '',
                        vehicleType: '',
                        fuelUnit: '',
                        quantity: 0,
                        mass: 0,
                        laden: '',
                        transportMode: '',
                        margin: '',
                        region: '',
                        allocation: 0,
                        totalAllocation: 0,
                        occupancyRate: 0,
                        electricity: 0,
                        refrigerant: '',
                        leakage: 0,
                        averageDays: 0,
                        storageEF: 0,
                        description: '',
                      })
                    }
                    required
                  >
                    <option value="">Select Type</option>
                    {typeOption().map((fuel) => (
                      <option key={fuel} value={fuel}>
                        {fuel}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {/* CalculateBy Dropdown (Transportation)*/}
              {editableData.reportBy === 'Fuel Consumption' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Calculate By
                  </label>
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.calculateBy}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        calculateBy: e.target.value,
                      })
                    }
                    required
                  >
                    <option value="">Select Type</option>
                    <option value="Fuel Consumed">Fuel Consumed</option>
                    <option value="Refrigerant Leaked">
                      Refrigerant Leaked
                    </option>
                  </select>
                </div>
              )}

              {/* Vehicle Mode Dropdown*/}
              {editableData.reportBy === 'Distance Travelled' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Vehicle Mode
                  </label>
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.vehicleMode}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        vehicleMode: e.target.value,
                      })
                    }
                    required
                  >
                    <option value="">Select Mode</option>
                    {Object.keys(vehicleMode).map((vehicle) => (
                      <option key={vehicle} value={vehicle}>
                        {vehicle}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {/* Vehicle Type Dropdown*/}
              {editableData.vehicleMode !== '' &&
                editableData.reportBy === 'Distance Travelled' && (
                  <div className="mb-4">
                    <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                      Vehicle Type
                    </label>
                    <select
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      value={editableData.vehicleType}
                      onChange={(e) =>
                        setEditableData({
                          ...editableData,
                          vehicleType: e.target.value,
                        })
                      }
                      required
                    >
                      <option value="">Select Type</option>
                      {vehicleMode[editableData.vehicleMode].map((vehicle) => (
                        <option key={vehicle} value={vehicle}>
                          {vehicle}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

              {/* Allocation Input */}
              {editableData.reportBy === 'Site-Specific Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    <p>
                      Space Allocated (m<sup>2</sup> or m<sup>3</sup>)
                    </p>
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.allocation}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        allocation: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Total Allocation Input */}
              {editableData.reportBy === 'Site-Specific Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    <p>
                      Total Space (m<sup>2</sup> or m<sup>3</sup>)
                    </p>
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.totalAllocation}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        totalAllocation: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Region Dropdown (Site Specific))*/}
              {editableData.reportBy === 'Site-Specific Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Region
                  </label>
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.region}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        region: e.target.value,
                      })
                    }
                    required
                  >
                    <option value="">Select Type</option>
                    {malaysiaRegion.map((region) => (
                      <option key={region} value={region}>{region}</option>
                    ))}
                  </select>
                </div>
              )}

              {/* Occupancy Rate Input (Site Specific)*/}
              {editableData.reportBy === 'Site-Specific Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Occupancy Rate of Facility (0 to 1)
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.occupancyRate}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        occupancyRate: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Fuel Dropdown */}
              {editableData.emissionType !== '' &&
                ((editableData.reportBy === 'Fuel Consumption' &&
                  editableData.calculateBy === 'Fuel Consumed') ||
                  editableData.reportBy === 'Site-Specific Method') && (
                  <div className="mb-4">
                    <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                      Fuel Type
                    </label>
                    {editableData.reportBy === 'Site-Specific Method' ? (
                      <select
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                        value={editableData.fuel}
                        onChange={(e) =>
                          setEditableData({
                            ...editableData,
                            fuel: e.target.value,
                          })
                        }
                      >
                        <option value="">Select Fuel</option>
                        {fuel.map((fuel) => (
                          <option key={fuel} value={fuel}>
                            {fuel}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <select
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                        value={editableData.fuel}
                        onChange={(e) =>
                          setEditableData({
                            ...editableData,
                            fuel: e.target.value,
                          })
                        }
                        required
                      >
                        <option value="">Select Fuel</option>
                        {fuel.map((fuel) => (
                          <option key={fuel} value={fuel}>
                            {fuel}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                )}

              {/* Unit Dropdown */}
              {((editableData.calculateBy &&
                editableData.calculateBy === 'Fuel Consumed') ||
                editableData.reportBy === 'Site-Specific Method') && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Fuel Unit
                  </label>
                  {/* In site specific method, fuel are not required to be filled */}
                  {editableData.reportBy === 'Site-Specific Method' ? (
                    <select
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      value={editableData.fuelUnit}
                      onChange={(e) =>
                        setEditableData({
                          ...editableData,
                          fuelUnit: e.target.value,
                        })
                      }
                    >
                      <option value="">Select Unit</option>
                      {/* To adapt to the availability of fuel's emission factor */}
                      {editableData.fuel &&
                        Object.keys(fuelEF[editableData.fuel]['CO2'])
                          .filter(
                            (key) =>
                              fuelEF[editableData.fuel]['CO2'][key] !== 0,
                          )
                          .map((unit) => (
                            <option key={unit} value={unit}>
                              {unit}
                            </option>
                          ))}
                    </select>
                  ) : (
                    <select
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      value={editableData.fuelUnit}
                      onChange={(e) =>
                        setEditableData({
                          ...editableData,
                          fuelUnit: e.target.value,
                        })
                      }
                      required
                    >
                      <option value="">Select Unit</option>
                      {editableData.fuel &&
                        Object.keys(fuelEF[editableData.fuel]['CO2'])
                          .filter(
                            (key) =>
                              fuelEF[editableData.fuel]['CO2'][key] !== 0,
                          )
                          .map((unit) => (
                            <option key={unit} value={unit}>
                              {unit}
                            </option>
                          ))}
                    </select>
                  )}
                </div>
              )}

              {/* Mass Input */}
              {editableData.reportBy === 'Distance Travelled' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Mass of Transported Goods (Tonnes)
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.mass}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        mass: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Quantity Input */}
              {((editableData.reportBy !== 'Fuel Consumption' &&
                editableData.reportBy) ||
                editableData.calculateBy === 'Fuel Consumed') && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    {quantityLabel()}
                  </label>
                  {editableData.reportBy === 'Site-Specific Method' ? (
                    <input
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      value={editableData.quantity}
                      onChange={(e) =>
                        setEditableData({
                          ...editableData,
                          quantity: e.target.value,
                        })
                      }
                    />
                  ) : (
                    <input
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      value={editableData.quantity}
                      onChange={(e) =>
                        setEditableData({
                          ...editableData,
                          quantity: e.target.value,
                        })
                      }
                      required
                    />
                  )}
                </div>
              )}

              {/* Average Days Input */}
              {editableData.reportBy === 'Average-Data Method' &&
                editableData.reportBy && (
                  <div className="mb-4">
                    <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                      Average Days Stored (days)
                    </label>
                    <input
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      value={editableData.averageDays}
                      onChange={(e) =>
                        setEditableData({
                          ...editableData,
                          averageDays: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                )}

              {/* Storage EF Input */}
              {editableData.reportBy === 'Average-Data Method' &&
                editableData.reportBy && (
                  <div className="mb-4">
                    <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                      <p>
                        Emission Factor of Storage (kgCO<sub>2</sub>e/m
                        <sub>3</sub>/day)
                      </p>
                    </label>
                    <input
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      value={editableData.storageEF}
                      onChange={(e) =>
                        setEditableData({
                          ...editableData,
                          storageEF: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                )}

              {/* Transport Mode Dropdown*/}
              {editableData.reportBy === 'Amount Spent' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Transport Mode
                  </label>
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.transportMode}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        transportMode: e.target.value,
                      })
                    }
                    required
                  >
                    <option value="">Select Mode</option>
                    {transportMode.map((mode) => (
                      <option key={mode} value={mode}>
                        {mode}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {/* Supply Chain Dropdown*/}
              {editableData.reportBy === 'Amount Spent' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Supply Chain Margin
                  </label>
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.margin}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        margin: e.target.value,
                      })
                    }
                    required
                  >
                    <option value="">Select Type</option>
                    <option value="With">With Margin</option>
                    <option value="Without">Without Margin</option>
                  </select>
                </div>
              )}
              {/* Laden Input */}
              {editableData.reportBy === 'Distance Travelled' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Laden
                  </label>
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.laden}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        laden: e.target.value,
                      })
                    }
                    required
                  >
                    <option value="">Select Laden Type</option>
                    {/* To adapt to the availability of emission factor for different laden type*/}
                    {editableData.vehicleMode &&
                      editableData.vehicleType &&
                      Object.keys(
                        vehicleEF[editableData.vehicleMode][
                          editableData.vehicleType
                        ]['CO2e'],
                      )
                        .filter(
                          (key) =>
                            vehicleEF[editableData.vehicleMode][
                              editableData.vehicleType
                            ]['CO2e'][key] !== 0,
                        )
                        .map((unit) => (
                          <option key={unit} value={unit}>
                            {unit}
                          </option>
                        ))}
                    {/* <option value="0">
                        0%
                      </option>
                      <option value="50">
                        50%
                      </option>
                      <option value="100">
                        100%
                      </option>
                      <option value="Average">
                        Average
                      </option> */}
                  </select>
                </div>
              )}

              {/* Electricity Consumption Input */}
              {editableData.reportBy === 'Site-Specific Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Site-Specific Electricity Consumption (kWh)
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.electricity}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        electricity: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Refrigerant Type Dropdown */}
              {(editableData.reportBy === 'Site-Specific Method' ||
                (editableData.reportBy === 'Fuel Consumption' &&
                  editableData.calculateBy === 'Refrigerant Leaked')) && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Refrigerant Type
                  </label>
                  {editableData.reportBy === 'Site-Specific Method' ? (
                    <select
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      value={editableData.refrigerant}
                      onChange={(e) =>
                        setEditableData({
                          ...editableData,
                          refrigerant: e.target.value,
                        })
                      }
                    >
                      <option value="">Select Type</option>
                      {refrigerant.map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <select
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      value={editableData.refrigerant}
                      onChange={(e) =>
                        setEditableData({
                          ...editableData,
                          refrigerant: e.target.value,
                        })
                      }
                      required
                    >
                      <option value="">Select Type</option>
                      {refrigerant.map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              )}

              {/* Refrigerant Leakage Input */}
              {(editableData.reportBy === 'Site-Specific Method' ||
                editableData.calculateBy === 'Refrigerant Leaked') && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Refrigerant Leakage (kg)
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.leakage}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        leakage: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Description Input */}
              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Description
                </label>
                <textarea
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={editableData.description}
                  onChange={(e) =>
                    setEditableData({
                      ...editableData,
                      description: e.target.value,
                    })
                  }
                  required
                />
              </div>

              <div className="flex justify-end mt-4">
                <button
                  type="button"
                  onClick={() => setShowContentModal(false)}
                  className="bg-gray-300 dark:bg-gray-600 dark:text-gray-200 px-4 py-2 rounded mr-2"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}

export default ReportTransportationDistribution
